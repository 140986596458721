<template>
  <div class="news-template-container">
    <div class="news-template"></div>
  </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>
  .news-template-container {
    width: 100%;
    background-color: #fafbfc;
    min-height: 100%;
    padding-bottom: 295px;
    box-sizing: border-box;
  }
  .news-template {
    width: 1125px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 100px;
  }
</style>
